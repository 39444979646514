export default {
  state: {
    taxNumbers: [
      { id: 1, text: 'Отриманий' },
      { id: 2, text: 'Не отримувався' },
      { id: 3, text: 'Оформлено відмову від ІПН' },
    ],
    regionsList: [],
    schools: [],
    universitiesList: [],
    privilegeDocumentTypes: [],
    statusesList: [],
    /* representatorDocumentsList: [
            {id: 1, text: 'Паспорт громадянина України'},
            {id: 2, text: 'ID картка'},
        ], */
    representetiveRelationshipsList: [
      { id: 1, text: 'мати' },
      { id: 2, text: 'батько' },
      { id: 3, text: 'опікун' },
    ],
    adultOfficialDocumentsList: [
      { id: 10, text: 'ID картка' },
      { id: 20, text: 'Паспорт громадянина України' },
    ],
    pupilOfficialDocumentsList: [
      { id: 10, text: 'ID картка' },
      { id: 50, text: 'Свідоцтво про народження' },
    ],
    sexList: [
      { id: 1, text: 'чоловіча' },
      { id: 2, text: 'жіноча' },
    ],
    basisList: [
      { id: 1, text: 'Перша видача' },
      { id: 2, text: 'Карту втрачено чи пошкоджено' },
      { id: 3, text: 'Картка несправна' },
      { id: 4, text: 'Перевипуск по закінченню терміну дії' },
      { id: 5, text: 'Заводський брак' },
      { id: 6, text: 'Помилка персоналізації' },
    ],
    cardsTypes: [
      // 'Пільгова МКВ',
      { id: 1, text: 'Студентська МКВ' },
      { id: 2, text: 'Учнівська МКВ' },
      { id: 3, text: 'Загальна МКВ' },
    ],
    STUDENT_ID: 1,
    PUPIL_ID: 2,
    MUNICIPAL_ID: 3,

    pupilPrivilegeDocument: [
      { id: 1, text: 'Учнівський квиток' },
    ],
    studentPrivilegeDocument: [
      { id: 2, text: 'Студентський квиток' },
    ],
    registrationList: [
      { id: 1, text: 'Об\'єднана територіальна громада м. Вінниця' },
      { id: 2, text: 'смт. Десна' },
    ],
    imageRequirements: [
      'Фото має бути зроблене не пізніше, як за 6 місяців.',
      'Зображення має бути на світлому фоні, високої якості, різке та контрастне.',
      'Фото не має бути відсканованою або перефотографованою копією із інших документів (паспортів, водійських прав, фотокарток, фотоальбомів тощо).',
      'На фото не має бути зайвих об’єктів (печаток, водяних знаків, голограм, рук, людей, будівель, рослин, об’єктів інтер’єру тощо).',
      '80% площі зображення має займати обличчя на світлому фоні.',
      'Обличчя на фотографії має розміщуватись у центрі, на світлому фоні, точно в анфас, без поворотів голови та плечей.',
      'Вираз обличчя має бути нейтральним, посмішки та кривляння не допускаються.',
      'Погляд має бути направлений прямо у камеру. Обличчя має бути відкритим: волосся не має закривати очі.',
      'На голові не має бути головних уборів та аксесуарів (темних окулярів, вінків, навушників, фати, капюшонів тощо)',
      'Якщо Ви постійно носите оптичні окуляри, то і фотографуватись потрібно в окулярах (без затемнення).',
      'При обробці фото не використовуйте корегуючі фільтри і ефекти (чорно-біле фото, сепія, розмиття тощо).',
      'Фотографія невідповідної якості прирівнюється до неправильно поданих даних.',
    ],
    paymentsList: [
      {
        id: 0,
        text: 'Не оплачено',
      },
      {
        id: 1,
        text: 'Очікується оплата',
      },
      {
        id: 2,
        text: 'Оплачено',
      },
    ],
  },
  getters: {
    getStatusesList(state) {
      return state.statusesList;
    },
    getPaymentList(state) {
      return state.paymentsList;
    },
    femaleGender(state) {
      return state.sexList[1].id;
    },
    getStudentCardID(state) {
      return state.STUDENT_ID;
    },
    getPupilCardID(state) {
      return state.PUPIL_ID;
    },
    getMunicipalCardID(state) {
      return state.MUNICIPAL_ID;
    },
    getImageRequirements(state) {
      return state.imageRequirements;
    },
    taxNumbers(state) {
      return state.taxNumbers;
    },
    regionsList(state) {
      return state.regionsList;
    },
    schools(state) {
      return state.schools;
    },
    /* representatorDocumentsList(state) {
             return state.representatorDocumentsList;
         }, */
    universitiesList(state) {
      return state.universitiesList;
    },
    representetiveRelationshipsList(state) {
      return state.representetiveRelationshipsList;
    },
    getRepresentetiveRelationshipsList: state => id => state.representetiveRelationshipsList.find(item => item.id === id).text,
    adultOfficialDocumentsList(state) {
      return state.adultOfficialDocumentsList;
    },
    getAdultOfficialDocument: state => id => state.adultOfficialDocumentsList.find(item => item.id === id)?.text,
    sexList(state) {
      return state.sexList;
    },
    getUserGender: state => id => state.sexList.find(item => item.id === id).text,
    basisList(state) {
      return state.basisList;
    },
    cardsTypes(state) {
      return state.cardsTypes;
    },
    getCardType: state => id => state.cardsTypes.find(item => item.id === id).text,
    privilegeDocumentTypes(state) {
      return state.privilegeDocumentTypes;
    },
    getPrivilegeDocument: state => id => state.privilegeDocumentTypes.find(item => item.id === id)?.text,

    studentPrivilegeDocument(state) {
      return state.studentPrivilegeDocument;
    },
    registrationList(state) {
      return state.registrationList;
    },
    pupilOfficialDocumentsList(state) {
      return state.pupilOfficialDocumentsList;
    },
    getPupilOfficialDocument: state => id => state.pupilOfficialDocumentsList.find(item => item.id === id)?.text,
    pupilPrivilegeDocument(state) {
      return state.pupilPrivilegeDocument;
    },
    getIdCardID(state) {
      return state.adultOfficialDocumentsList[0].id;
    },
    getPassportID(state) {
      return state.adultOfficialDocumentsList[1].id;
    },
    /* getPupilCardID(state) {
            return state.cardsTypes[1].id;
        },
        getStudentCardID(state) {
            return state.cardsTypes[0].id;
        },
        getMunicipalCard(state) {
            return state.cardsTypes[2].id;
        }, */
    getReceivedTaxNumberID(state) {
      return state.taxNumbers[0].id;
    },
    getAbandonedTaxNumberID(state) {
      return state.taxNumbers[1].id;
    },
    getPupilIdCardID(state) {
      return state.pupilOfficialDocumentsList[0].id;
    },
    getPupilBirthCertificateID(state) {
      return state.pupilOfficialDocumentsList[1].id;
    },
    getStudentPrivilegeDocumentID(state) {
      return state.studentPrivilegeDocument[0].id;
    },
    getPupilPrivilegeDocumentID(state) {
      return state.pupilPrivilegeDocument[0].id;
    },
    getCurrentUniversityType: state => id => state.universitiesList.find(elem => elem.id === Number(id)).type,
    getUserRegion: state => id => state.regionsList.find(item => item.id === id).text,
  },
  mutations: {
    setStatusesList(state, payload) {
      state.statusesList = payload
    },
    setUniversitiesList(state, payload) {
      state.universitiesList = payload
    },
    setSchools(state, payload) {
      state.schools = payload
    },
    setPrivilegeDocumentTypes(state, payload) {
      state.privilegeDocumentTypes = payload
    },
    setRegionsList(state, payload) {
      state.regionsList = payload
    },
  },
  actions: {
    setStatusesList(context, payload) {
      context.commit('setStatusesList', payload)
    },
    setUniversitiesList(context, payload) {
      context.commit('setUniversitiesList', payload)
    },
    setSchools(context, payload) {
      context.commit('setSchools', payload)
    },
    setPrivilegeDocumentTypes(context, payload) {
      context.commit('setPrivilegeDocumentTypes', payload)
    },
    setRegionsList(context, payload) {
      context.commit('setRegionsList', payload)
    },
  },

};
